import React, { ComponentProps, lazy, Suspense, useEffect } from 'react';

import getValidLanguage from 'helpers/getValidLanguage';
import StaticHomepage from 'pages/StaticHomepage';
import { useTranslation } from 'react-i18next';
import {
  Route as RRDRouter,
  Redirect as RRDRedirect,
  useLocation,
} from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { TLanguage } from 'config/tenancy';
import { makeHTTPProvider } from 'shared/infra/providers';
import { AppNavbar } from 'shared/presentation/components/templates';
import {
  Navigator,
  Redirect,
  Route,
  Switch,
} from 'shared/presentation/contexts';
import appModules from 'shared/presentation/modules';

import CircularLoading from './components/CircularLoading';
import FixedBottomModal from './components/FixedBottomModal';
import ScrollToTop from './components/ScrollToTop';
import InitAxios from './helpers/InitAxios';
import { trackData } from './helpers/Trackers';
import Home from './pages/home/Home';
import NotFound from './pages/notFound/NotFound';

const AsyncOurTechnology = lazy(
  () => import('./pages/ourTechnology/Technology'),
);
const AsyncCart = lazy(() => import('./pages/cart/Cart'));
const AsyncLogin = lazy(() => import('./pages/login/Login'));
const AsyncCombos = lazy(() => import('./pages/packages/Combos'));
const AsyncPackage = lazy(() => import('./pages/packages/Package'));
const AsyncCombo = lazy(() => import('./pages/packages/Combo'));
const AsyncUser = lazy(() => import('./pages/user/User'));
const AsyncCheckout = lazy(() => import('./pages/checkout'));
const AsyncScheduler = lazy(() => import('./pages/scheduler'));
const AsyncWhoWeAre = lazy(() => import('./pages/whoWeAre'));
const AsyncPolitics = lazy(() => import('./pages/politics'));
const AsyncTerms = lazy(() => import('./pages/terms'));
const AsyncFaq = lazy(() => import('./pages/Faq'));
const AsyncFranchises = lazy(() => import('./pages/Franchises'));
const AsyncClientArea = lazy(() => import('./pages/ClientArea'));
const AsyncGhostPage = lazy(() => import('./components/Ghost'));
const AsyncPageConsent = lazy(() => import('./pages/Consent'));
const AsyncBenefitsPage = lazy(() => import('./pages/Benefits'));
const AsyncIngenicoCardTransfer = lazy(
  () => import('./pages/IngenicoCardTransfer'),
);
const AsyncBlackBoom = lazy(() => import('./pages/BlackBoom'));
const AsyncHidden = lazy(() => import('./pages/Hidden'));
const AsyncPIXPendencyPayment = lazy(
  () => import('./pages/PIXPendencyPayment'),
);

const modulesRoutes = appModules.routes;

window.onbeforeunload = () => {
  localStorage.removeItem('rescheduleId');
  localStorage.removeItem('scheduleIdentificationHash');
  localStorage.removeItem('@vialaser:checkout_customer_id');
};

const checkIfIsBlockedRoute = ({
  path,
  language,
}: {
  path: string;
  language: TLanguage;
}) => {
  if (language === 'es') {
    return (
      !path.includes('checkout') &&
      !path.includes('homepage') &&
      !path.includes('cliente') &&
      !path.includes('beneficio-indicacao') &&
      !path.includes('privacidade') &&
      !path.includes('termos')
    );
  }

  if (language === 'es-CR') {
    return (
      !path.includes('checkout') &&
      !path.includes('homepage') &&
      !path.includes('cliente') &&
      !path.includes('privacidade') &&
      !path.includes('termos')
    );
  }

  return path.includes('homepage');
};

const getDefaultUnblockedRoute = ({ language }: { language: TLanguage }) => {
  if (language === 'es' || language === 'es-CR') return '/homepage';
  if (language === 'pt') return '/';

  return '';
};

const CustomRoute: React.FC<
  ComponentProps<typeof RRDRouter> & {
    disabled?: boolean;
    disabledTenancies?: TLanguage[];
    path: string;
  }
> = props => {
  const { i18n } = useTranslation();

  if (props.disabled) return null;

  const isDisabledRoute = props.disabledTenancies?.includes(
    getValidLanguage(i18n.language),
  );

  const isBlockedRoute = checkIfIsBlockedRoute({
    path: props.path,
    language: getValidLanguage(i18n.language),
  });

  if (isBlockedRoute || isDisabledRoute) {
    return (
      <RRDRedirect
        to={getDefaultUnblockedRoute({
          language: getValidLanguage(i18n.language),
        })}
      />
    );
  }

  return <RRDRouter {...props} />;
};

function Routes() {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    makeHTTPProvider().setLanguage(getValidLanguage(i18n.language));

    const sessionId = localStorage.getItem('@vialaser:sessionId');
    if (!sessionId) {
      localStorage.setItem('@vialaser:sessionId', uuid());
    }

    trackData(location.pathname, 'PageView', {});
  }, [i18n.language, location.pathname]);

  useEffect(() => {
    makeHTTPProvider().setLanguage(getValidLanguage(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    if (!location.pathname.startsWith('/agendamento')) {
      localStorage.removeItem('rescheduleId');
      localStorage.removeItem('scheduleIdentificationHash');
    }
  }, [location]);

  useEffect(() => {
    const isBlockedRoute = checkIfIsBlockedRoute({
      path: location.pathname,
      language: getValidLanguage(i18n.language),
    });

    if (isBlockedRoute) return;

    const dataLayer = window.dataLayer || [];
    const eventKey = 'dataLayerInit';

    const isEventAlreadyDispatched = dataLayer.find(
      element => element.event === eventKey,
    );

    if (!isEventAlreadyDispatched) {
      dataLayer.push({
        event: eventKey,
        originalLocation: document.location.href,
      });
    }
  }, [i18n.language, location.pathname]);

  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <>
            <AppNavbar />
            <CircularLoading />
          </>
        }
      >
        <InitAxios />

        <Switch>
          {modulesRoutes.map(route => {
            if (route.type === 'navigator') {
              return <Navigator key={route.path} {...route} />;
            }

            if (route.type === 'redirect') {
              return <Redirect key={route.from} {...route} />;
            }

            return <Route key={route.path} {...route} />;
          })}

          <CustomRoute exact path="/" component={Home} />
          <CustomRoute
            path="/redirect-campanha-whatsapp"
            component={AsyncGhostPage}
          />
          <CustomRoute exact path="/pacotes" component={AsyncCombos} />
          <CustomRoute
            exact
            path="/nossa-tecnologia"
            component={AsyncOurTechnology}
          />
          <RRDRedirect
            from="/pacotes/MASCULINO/faixa-de-barba"
            to="/pacotes/rosto"
          />

          <RRDRedirect
            from="/pacotes/FEMININO/pernas-inteiras"
            to="/pacotes/pernas-inteiras"
          />

          <RRDRedirect
            from="/pacotes/FEMININO/perianal"
            to="/pacotes/perianal"
          />

          <RRDRedirect
            from="/pacotes/FEMININO/meia-perna"
            to="/pacotes/meia-perna"
          />

          <RRDRedirect from="/pacotes/FEMININO/axilas" to="/pacotes/axilas" />

          <RRDRedirect from="/pacotes/FEMININO/queixo" to="/pacotes/queixo" />

          <RRDRedirect from="/pacotes/FEMININO/barriga" to="/pacotes/barriga" />

          <RRDRedirect from="/pacotes/FEMININO/costas" to="/pacotes/costas" />

          <RRDRedirect
            from="/pacotes/FEMININO/posterior-da-coxa"
            to="/pacotes/posterior-da-coxa"
          />

          <RRDRedirect from="/pacotes/FEMININO/nuca" to="/pacotes/nuca" />

          <RRDRedirect from="/pacotes/FEMININO/testa" to="/pacotes/testa" />

          <RRDRedirect from="/pacotes/FEMININO/lombar" to="/pacotes/lombar" />

          <RRDRedirect from="/pacotes/FEMININO/glabela" to="/pacotes/glabela" />

          <RRDRedirect from="/pacotes/FEMININO/joelhos" to="/pacotes/joelhos" />

          <RRDRedirect
            from="/pacotes/FEMININO/virilha-completa"
            to="/pacotes/virilha"
          />

          <RRDRedirect from="/pacotes/MASCULINO/axilas" to="/pacotes/axilas" />

          <RRDRedirect from="/pacotes/FEMININO/pes" to="/pacotes/pes" />

          <RRDRedirect from="/pacotes/FEMININO/busto" to="/pacotes/peito" />

          <RRDRedirect from="/pacotes/MASCULINO/costas" to="/pacotes/costas" />

          <RRDRedirect
            from="/pacotes/FEMININO/aureolas"
            to="/pacotes/areolas"
          />

          <RRDRedirect from="/pacotes/FEMININO/pescoco" to="/pacotes/pescoco" />

          <RRDRedirect
            from="/pacotes/FEMININO/buco"
            to="/pacotes/labio-superior"
          />
          <CustomRoute path="/pacotes/:sex/:name" component={AsyncPackage} />
          <CustomRoute path="/promocoes/:sex/:name" component={AsyncCombo} />
          <CustomRoute path="/pacotes/:name" component={AsyncPackage} />
          <CustomRoute path="/promocoes/:name" component={AsyncCombo} />

          <CustomRoute
            path="/transferencia-ingenico"
            component={AsyncIngenicoCardTransfer}
          />

          <CustomRoute path="/carrinho" component={AsyncCart} />
          <CustomRoute path="/carrinho/:hash" component={AsyncCart} />
          <CustomRoute path="/checkout" component={AsyncCheckout} />
          <CustomRoute path="/login" component={AsyncLogin} />
          <CustomRoute path="/cliente" component={AsyncUser} />
          <CustomRoute
            path="/trocaCartaoReparcelamento"
            component={AsyncUser}
          />
          <CustomRoute path="/agendamento" component={AsyncScheduler} />
          <CustomRoute path="/quem-somos" component={AsyncWhoWeAre} />
          <CustomRoute
            path="/politicas-de-privacidade"
            component={AsyncPolitics}
          />
          <CustomRoute path="/termos-de-uso" component={AsyncTerms} />
          <CustomRoute path="/faq" component={AsyncFaq} />
          <CustomRoute path="/franquias" component={AsyncFranchises} />
          <CustomRoute path="/area-cliente" component={AsyncClientArea} />

          <CustomRoute path="/entrar" component={AsyncHidden} />

          <CustomRoute
            path="/consentimento/:encodeClientId"
            component={AsyncPageConsent}
          />
          <RRDRouter
            path="/pendencias/pix/:hash"
            component={AsyncPIXPendencyPayment}
          />

          <CustomRoute
            path="/orcamento/:hash"
            component={AsyncBenefitsPage}
            disabled
          />

          <CustomRoute path="/homepage" component={StaticHomepage} />

          <CustomRoute
            path="/blackboom/:hash"
            component={AsyncBlackBoom}
            disabled
          />

          <RRDRouter component={NotFound} />
        </Switch>

        <FixedBottomModal />
      </Suspense>
    </>
  );
}

export default Routes;
